import React from "react"
import { useStaticQuery, graphql, PageProps } from "gatsby"
import MarkdownContent from "../Basic/MarkdownContent"

const Privacy = (props: PageProps) => { 
    
    const data = useStaticQuery(graphql`
        query PrivacyMarkdown {
            markdownRemark(frontmatter: {slug: {eq: "privacy"}}) {
                html
                frontmatter {
                  slug
                  title
                }
            }
        }
    `)
    const { markdownRemark } = data;
    return (
        <div className="page-wrapper" id="privacy">
            <div className="container flex">
                <div className="page-container">
                    <h1 className="page-title">{markdownRemark.frontmatter.title}</h1>
                    <MarkdownContent html={markdownRemark.html} />    
                </div>
            </div>
        </div>
    )
}


export default Privacy