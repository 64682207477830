import React, { useRef, useEffect} from "react"
import { PageProps } from "gatsby"
// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import content from '@src/content/hero.json'
import { useStaticQuery, graphql } from "gatsby"
import lottie from "lottie-web";
import {facebookUrl} from '@src/config';
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Hero = (props: PageProps) => { 
    
    const animationRef = useRef()
    const data = useStaticQuery(graphql`
        query Hero {
            allHeroJson {
                nodes {
                    title
                    text
                    id
                    button
                    animation {
                        publicURL
                    }
                }
            }
        }
    `)  
    const node = data.allHeroJson.nodes[0]
    useEffect(() => {
        lottie.loadAnimation({
            container: animationRef.current, // the dom element that will contain the animation
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: node.animation.publicURL // the path to the animation json
        });
        lottie.setSpeed(0.3); 
    }, [])


    return (
        <div className="hero" id="hero">
            <div className="container flex">
                <div className="info-text">
                    <h2>
                        {node.title}
                    </h2>
                    <a
                        href={facebookUrl} 
                        title='Contattaci'
                        className="btn cta"
                        target="_blank"
                    >
                        {node.button}
                    </a>
                    <p>
                        {node.text}
                    </p>
                </div>
                <div className="animation" ref={animationRef}></div>
            </div>
        </div>
    )
}


export default Hero