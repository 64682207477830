import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"
import Privacy from "@components/Privacy"
import Hero from "@components/Hero"


const PrivacyPage = () => ( 
  <Layout>
    <SEO title="Informativa sulla privacy" />
    <Hero />
    <Privacy />
  </Layout>
)


export default PrivacyPage
